<template>
  <div class="view-signup">
    <v-row justify="center">
      <v-col cols="12" sm="8" md="8">
        <v-card class="elevation-12">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>Registrazione</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-form ref="form" v-model="valid" lazy-validation @submit.prevent>
            <v-card-text>
              <!-- Alert utilizzato in caso di errore -->
              <v-alert
                type="error" dismissible v-model="alert" class="mb-4"
                transition="scale-transition">
                {{ error }}
              </v-alert>

              <!-- Alert utilizzato in caso di successo -->
              <v-alert
                type="success" dismissible v-model="success" class="mb-4"
                transition="scale-transition">
                Registrazione avvenuta con successo!
                Controlla la tua casella di posta per confermare l'indirizzo email.
              </v-alert>

              <p>
                <strong class="success--text">Compila il modulo</strong> sottostante
                per registrarti a KEIM Easy e ottenere l'accesso
                alle funzionalità riservate ai nostri clienti.
              </p>

              <v-autocomplete
                v-model="type"
                :disabled="submitting"
                :items="userTypes"
                label="Tipologia"
                placeholder="Che tipo di utente sei?"
                hide-details
                filled
                required
                :rules="[(v) => !!v || 'La tipologia di utente è richiesta']"
              ></v-autocomplete>

              <v-row>
                <v-col cols="12" v-if="hasCompany" class="pb-0">
                  <v-text-field type="text"
                    v-model="company"
                    :disabled="submitting"
                    label="Ragione sociale"
                    :rules="[v => !!v || 'La ragione sociale è richiesta']"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6"
                  :class="{ 'pt-0': hasCompany }">
                  <v-text-field type="text"
                    v-model="firstName"
                    :disabled="submitting"
                    label="Nome"
                    :rules="[v => !!v || 'Il nome dell\'utente è richiesto']"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6"
                  :class="{ 'pt-0': hasCompany }">
                  <v-text-field type="text"
                    v-model="lastName"
                    :disabled="submitting"
                    label="Cognome"
                    :rules="[v => !!v || 'Il cognome dell\'utente è richiesto']"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" class="pt-0">
                  <v-text-field type="text" dense
                    v-model="address"
                    :disabled="submitting"
                    label="Indirizzo"
                  ></v-text-field>
                </v-col>

                <v-col cols="2">
                  <v-text-field type="text" dense
                    v-model="postalCode"
                    :disabled="submitting"
                    label="CAP"
                  ></v-text-field>
                </v-col>

                <v-col cols="5">
                  <v-text-field type="text" dense
                    v-model="city"
                    :disabled="submitting"
                    label="Città"
                  ></v-text-field>
                </v-col>

                <v-col cols="5">
                  <v-autocomplete dense
                    v-model="province"
                    :disabled="submitting"
                    :items="provinces"
                    clearable
                    label="Provincia"
                    :rules="[v => !!v || 'La provincia è richiesta']"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="6">
                  <v-text-field ref="phone"
                    v-model="phone"
                    :disabled="submitting"
                    label="Telefono"
                    name="phone"
                    prepend-icon="mdi-phone"
                    type="tel"
                    :rules="[v => !!v || 'Il telefono è richiesto']"
                  />
                </v-col>

                <v-col cols="6">
                  <v-text-field ref="phone1"
                    v-model="phone1"
                    :disabled="submitting"
                    label="Telefono (opzionale)"
                    name="phone1"
                    prepend-icon="mdi-phone"
                    type="tel"
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field ref="email"
                    v-model="email"
                    :disabled="submitting"
                    label="Email"
                    name="email"
                    prepend-icon="mdi-email"
                    type="email"
                    :rules="[v => !!v || 'L\'indirizzo email è richiesto']"
                  />
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field ref="password"
                    v-model="password"
                    :disabled="submitting"
                    id="password"
                    label="Password"
                    name="password"
                    prepend-icon="mdi-lock"
                    type="password"
                    :rules="[v => !!v || 'La password è richiesta']"
                  />
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field ref="passwordConfirm"
                    v-model="passwordConfirm"
                    :disabled="submitting"
                    id="passwordConfirm"
                    label="Conferma password"
                    name="passwordConfirm"
                    prepend-icon="mdi-lock"
                    type="password"
                    :rules="[v => !!v || 'La conferma della password è richiesta']"
                  />
                </v-col>

                <v-col cols="12">
                  <v-checkbox
                    v-model="checkbox1"
                    color="success"
                    required
                    :rules="[(v) => !!v || 'Devi leggere e accettare le condizioni per registrarti e utilizzare il servizio']"
                  >
                    <template v-slot:label>
                      <div>
                        Ho letto e accetto le condizioni dell'<router-link :to="{ name: 'Privacy' }">Informativa
                          sul Trattamento dei Dati Personali</router-link>
                          (obbligatorio)
                      </div>
                    </template>
                  </v-checkbox>
                </v-col>

                <v-col cols="12">
                  <v-checkbox
                    v-model="checkbox2"
                    label="Acconsento all'invio di comunicazioni commerciali riguardanti nuovi prodotti o novità sui prodotti già esistenti (facoltativo)"
                    color="success"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <p class="mt-4">
                Premendo il pulsante <strong class="success--text">Registrati</strong>,
                ti verrà inviata una email con un link per confermare l'indirizzo email inserito.
                Se non ricevi l'email, controlla la cartella di posta indesiderata.
              </p>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn type="submit" color="success"
                :disabled="!valid || submitting"
                :loading="submitting"
                @click="onSignup">Registrati</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import { getProvince } from '@/utils/italy';
import { SIGNUP_USER } from '@/store/actions/users';
import { LOGOUT } from '@/store/actions/auth';

export default {
  name: 'Registrati',

  data: () => ({
    type: null,
    firstName: '',
    lastName: '',
    company: '',

    address: '',
    postalCode: '',
    city: '',
    province: null,

    phone: '',
    phone1: '',
    email: '',
    password: '',
    passwordConfirm: '',

    checkbox1: false,
    checkbox2: false,

    submitting: false,
    valid: true,
    alert: false,
    success: false,
    error: '',

    userTypes: [
      { value: 'privato', text: 'Privato' },
      { value: 'rivenditore', text: 'Rivenditore' },
      { value: 'venditore-esterno', text: 'Venditore esterno della rivendita' },
      { value: 'agente', text: 'Agente' },
      { value: 'imbianchino', text: 'Imbianchino' },
      { value: 'progettista', text: 'Progettista' },
      { value: 'keim', text: 'Funzionario KEIM' },
    ],
  }),

  created() {
    console.log(`${this.$options.name}: created()`); // eslint-disable-line no-console

    // Effettua sempre il logout
    this.$store.dispatch(LOGOUT);

    // Sposta il focus sull'input email
    this.$nextTick(() => this.$refs.email.focus());
  },

  computed: {
    provinces: () => getProvince(),

    hasCompany() {
      return ['rivenditore', 'venditore-esterno', 'agente', 'imbianchino', 'progettista', 'keim']
        .includes(this.type);
    },
  },

  methods: {
    async onSignup() {
      const formRef = this.$refs.form;

      if (!formRef.validate()) return;

      this.submitting = true;
      this.alert = false;
      this.success = false;
      this.error = '';

      if (this.password !== this.passwordConfirm) {
        this.error = 'Le password non corrispondono';
        this.alert = true;
        this.submitting = false;
        return;
      }

      try {
        await this.$store.dispatch(`users/${SIGNUP_USER}`, {
          type: this.type,
          company: this.hasCompany ? this.company : null,
          firstName: this.firstName,
          lastName: this.lastName,
          address: this.address,
          postalCode: this.postalCode,
          city: this.city,
          province: this.province,
          phone: this.phone,
          phone1: this.phone1,
          email: this.email,
          password: this.password,
          checkbox1: this.checkbox1,
          checkbox2: this.checkbox2,
        });

        // Se la registrazione è andata a buon fine, mostro l'alert di successo
        this.submitting = false;
        this.success = true;

        // Resetta i campi del form
        this.type = null;
        this.company = '';
        this.firstName = '';
        this.lastName = '';
        this.address = '';
        this.postalCode = '';
        this.city = '';
        this.province = null;
        this.phone = '';
        this.phone1 = '';
        this.email = '';
        this.password = '';
        this.passwordConfirm = '';
        this.checkbox1 = false;
        this.checkbox2 = false;
        formRef.resetValidation();

        // Sposta il focus sull'input email
        this.$nextTick(() => this.$refs.email.focus());
      } catch (error) {
        console.error(`${this.$options.name}.onSignup() error =`, error); // eslint-disable-line no-console

        this.submitting = false;

        switch (error) {
          case 'auth/email-already-exists':
            this.error = "L'indirizzo email è già in uso";
            break;

          default:
            this.error = `Errore sconosciuto: ${error}`;
            break;
        }

        this.alert = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.view-signup {
  flex-grow: 1;
  padding-top: 2rem;
}
</style>
